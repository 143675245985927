<template>
  <div class="wrap">
      <!-- 常规任务 -->
      <div class="title">
        <span class="titlespan" v-text="this.$route.query.edit?'编辑兼职':'新增兼职'"></span> 
        <el-button class="fr" size="small" @click="$router.push('/partTime')">返回</el-button>
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="兼职" name="first">
          <div class="partTit"><span>详情</span></div>
          <!-- 内容 -->
          <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="100px">
            <el-form-item label="兼职标题：" prop="title" style="width:50%">
              <el-input v-model="ruleForm.title" clearable></el-input>
            </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="工作日期：" prop="startTime">
                  <el-date-picker
                    style="width:540px"
                    v-model="timeRange" 
                    @change="getTimeRange($event,'startTime','endTime')"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="工作时间：" prop="workStartTime">
                  <el-time-select
                    style="width:255px"
                    placeholder="起始时间"
                    v-model="ruleForm.workStartTime"
                    :picker-options="{
                      start: '07:00',
                      step: '00:05',
                      end: '22:00'
                    }" class="w205">
                  </el-time-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item  prop="workEndTime">
                  <el-time-select
                    style="width:270px"
                    placeholder="结束时间"
                    v-model="ruleForm.workEndTime"
                    :picker-options="{
                      start: '07:00',
                      step: '00:05',
                      end: '23:00',
                      minTime: form.beginAt
                    }" class="w205">
                  </el-time-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="酬劳：" prop="payType">
                  <el-select v-model="ruleForm.payType" clearable filterable placeholder="选择类型" style="width:100%;">
                    <el-option label="日结" value="0" ></el-option>
                    <el-option label="月结" value="1" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item  prop="salary" >
                  <el-input placeholder="请输入内容" clearable v-model="ruleForm.salary" style="width:100%;" >
                    <template slot="append">{{ruleForm.payType=='0'?'元/日':'元/月'}}</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="招聘人数：" prop="persons">
                  <el-input placeholder="请输入人数" clearable  v-model="ruleForm.persons">
                    <template slot="append">人</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="招聘公司：" prop="orporateName">
                  <el-input placeholder="请输入公司名称" clearable  v-model="ruleForm.orporateName">
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作地址：" prop="address">
                  <el-input placeholder="请输入工作地址" clearable  v-model="ruleForm.address" >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作经验：" prop="experience">
                  <el-select v-model="ruleForm.experience" clearable filterable placeholder="请选择工作经验">
                    <el-option label="经验不限" value="经验不限"  ></el-option>
                    <el-option label="有工作经验" value="有工作经验"  ></el-option>
                    <el-option label="无工作经验" value="无工作经验" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学历要求：" prop="education">
                  <el-select v-model="ruleForm.education" clearable filterable placeholder="请选择学历要求">
                    <el-option label="专科及以上" value="专科及以上"  ></el-option>
                    <el-option label="专科" value="专科"  ></el-option>
                    <el-option label="本科 " value="本科"  ></el-option>
                    <el-option label="硕士" value="硕士"  ></el-option>
                    <el-option label="博士" value="博士" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col>
                <el-form-item label="工作福利：" prop="welfare">
                  <el-checkbox-group v-model="welfare" size="medium">
                    <el-checkbox-button  v-for="(city,index) in citiesGroup" :label="city" :key="index">{{city}}</el-checkbox-button>
                  </el-checkbox-group>
                  <el-button style="width:70px; height:35px; margin-top:15px; border-radius: 10px;" @click="openInput">其它</el-button>
                  <el-input style="width:400px; margin-left:10px; " clearable @change="pushArr" v-if="input" v-model="inputText" placeholder="输入其它内容">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="图片上传：" prop="ids">
              <base-upload
                @beforeUpload="handleBeforeUploadAvatar"
                :limit="9"
                v-model="ruleForm.ids"
                :uploadData="uploadData"
                tips
                fileType="multiple"
                buttonTxt="上传图片"
                class="components-upload-up"
              ></base-upload>
            </el-form-item>
            <el-form-item label="工作描述：" prop="content">
              <el-input type="textarea" clearable  v-model="ruleForm.content"></el-input>
            </el-form-item>
            <el-col :span="8">
              <el-form-item label="联系方式：" prop="phone">
                <div class="icon">
                <el-input v-model="ruleForm.phone" clearable  style="width:100%; marginRight:5%;" placeholder="请输入电话">
                  <template slot="prepend"><img class="img1" src="../../assets/images/operation/phone.svg" alt=""></template>
                </el-input>
              </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  prop="qq">
                <div class="icon">
                  <el-input v-model="ruleForm.qq" clearable style="width:100%; marginRight:5%;" placeholder="请输入QQ">
                    <template slot="prepend"><img class="img2" src="../../assets/images/operation/qq.svg" alt=""></template>
                  </el-input>
              </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  prop="wx">
                <div class="icon">
                  <el-input v-model="ruleForm.wx"  clearable style="width:100%; marginRight:5%;" placeholder="请输入微信">
                    <template slot="prepend"><img class="img3" src="../../assets/images/operation/weChat.svg" alt=""></template>
                  </el-input>
              </div>
              </el-form-item>
            </el-col>
              
            <div class="footer">
              <el-button @click="resetForm(ruleForm)">取消</el-button>
              <el-button type="primary" @click="submitForm(ruleForm)">确定</el-button>
            </div>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="承包" name="second">
          <div class="partTit"><span>详情</span></div>
          <el-form :model="form" :rules="formRules" ref="formRef" label-width="100px">
          <el-form-item label="项目名称：" prop="title" style="width:50%">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
            <el-row>
              <el-col :span="12">
                <el-form-item label="酬劳：" prop="salary">
                  <el-input placeholder="请输入内容" v-model="form.salary">
                    <template slot="append">完工结</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作地址：" prop="address">
                  <el-input placeholder="请输入工作地址" v-model="form.address" >
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作经验：" prop="experience">
                  <el-select v-model="form.experience" clearable filterable placeholder="请选择工作经验">
                    <el-option label="经验不限" value="经验不限"  ></el-option>
                    <el-option label="有工作经验" value="有工作经验"  ></el-option>
                    <el-option label="无工作经验" value="无工作经验" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学历要求：" prop="education">
                  <el-select v-model="form.education" clearable filterable placeholder="请选择学历要求">
                    <el-option label="专科及以上" value="专科及以上"  ></el-option>
                    <el-option label="专科" value="专科"  ></el-option>
                    <el-option label="本科 " value="本科"  ></el-option>
                    <el-option label="硕士" value="硕士"  ></el-option>
                    <el-option label="博士" value="博士" ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="工作描述：" prop="content">
              <el-input type="textarea" v-model="form.content"></el-input>
            </el-form-item>
            <el-form-item label="图片上传：" prop="ids" >
              <base-upload
                @beforeUpload="handleBeforeUploadAvatar"
                :limit="9"
                v-model="form.ids"
                :uploadData="uploadData"
                tips
                buttonTxt="上传图片"
                class="components-upload-up"
              ></base-upload>
            </el-form-item>
            <el-col :span="8">
              <el-form-item label="联系方式：" prop="phone">
                <div class="icon">
                <el-input v-model="form.phone" clearable  style="width:100%; marginRight:5%;" placeholder="请输入电话">
                  <template slot="prepend"><img class="img1" src="../../assets/images/operation/phone.svg" alt=""></template>
                </el-input>
              </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  prop="qq">
                <div class="icon">
                  <el-input v-model="form.qq" clearable style="width:100%; marginRight:5%;" placeholder="请输入QQ">
                    <template slot="prepend"><img class="img2" src="../../assets/images/operation/qq.svg" alt=""></template>
                  </el-input>
              </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item  prop="wx">
                <div class="icon">
                  <el-input v-model="form.wx"  clearable style="width:100%; marginRight:5%;" placeholder="请输入微信">
                    <template slot="prepend"><img class="img3" src="../../assets/images/operation/weChat.svg" alt=""></template>
                  </el-input>
              </div>
              </el-form-item>
            </el-col>
            <div class="footer">
              <el-button @click="resetForm(form)">取消</el-button>
              <el-button type="primary" @click="submitForm(form)">确定</el-button>
            </div>
          </el-form>
        </el-tab-pane>
      </el-tabs>
          
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedList from "@/components/common/PagedList";
import pageMixin from "@/mixins/pageMixin";
import BaseUpload from "../common/BaseUpload.vue";
import AreaTree from "../common/AreaTree"
import { log, mergeObject } from '@/common/utils'
export default {
  name:'newPart',
  components: {
    PagedList,
    BaseUpload,
    AreaTree
  },
  mixins: [pageMixin],
  data() {
    return{
      input:false,
      inputText:'',
      timeRange:'',
      gameTime:[],
      welfare:[],
      queryParams:{},
      activeName:'first',
      statusOptions:[],
      ruleForm: {
        type:'1',
        title: '',
        workStartTime: '',
        startTime:'',
        endTime:'',
        workEndTime:'',
        payType: '',
        salary: '',
        persons: '',
        orporateName:'',
        welfare:'',
        address:'',
        experience:'',
        education:'',
        ids:[],
        content:'',
        phone:'',
        qq:'',
        wx:''
      },
      rules: {
        title: [{ required: true, message: "请输入兼职标题", trigger: "blur" }],
        workStartTime: [{ required: true, message: "请选择工作开始日期", trigger: "blur" }],
        startTime: [{ required: true, message: "请选择工作开始时间", trigger: "blur" }],
        payType: [{ required: true, message: "请选择酬劳类型", trigger: "blur" }],
        salary: [{ required: true, message: "请输入金额", trigger: "blur" }],
        persons: [{ required: true, message: "请输入招聘人数", trigger: "blur" }],
        orporateName: [{ required: true, message: "请输入招聘公司名称", trigger: "blur" }],
        address: [{ required: true, message: "请输入招聘公司地址", trigger: "blur" }],
        experience: [{ required: true, message: "请选择工作经验", trigger: "blur" }],
        education: [{ required: true, message: "请选择学历", trigger: "blur" }],
        ids:[{ required: true, message: "请上传图片", trigger: "blur" }],
        phone:[{ required: true, message: "请输入手机号", trigger: "blur" }],
      },
      citiesGroup:['包吃', '包住', '饭补', '交通补贴','话补','房补'],
      form:{
        type: "2",
        title: "",
        salary: "",
        education: "",
        experience: "",
        address: "",
        content: "",
        ids: [],
        phone: "",
        qq: "",
        wx: ""
      },
      formRules:{
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        salary: [{ required: true, message: "请输入金额", trigger: "blur" }],
        education: [{ required: true, message: "请选择学历", trigger: "blur" }],
        experience: [{ required: true, message: "请选择工作经验", trigger: "blur" }],
        address: [{ required: true, message: "请输入工作经验", trigger: "blur" }],
        ids:[{ required: true, message: "请上传图片", trigger: "blur" }],
        phone:[{ required: true, message: "请输入手机号", trigger: "blur" }],
      },

      uploadData: {
        bizType: "",
        bizId: "",
        needStore: "1",
      },
    }
  },
  methods: {
    ...mapActions('partTime',["jobAddJob","jobGetDetail","jobDelete"]),
    //重新发布表单赋值
    async getDetail(){
      if(this.$route.query.edit){
        const res = await this.jobGetDetail({id:this.$route.query.id})
        if(res.job.type == 1){
          this.activeName = 'first'
          mergeObject(this.ruleForm,res.job)
          if(this.ruleForm.welfare) {
            this.citiesGroup = this.ruleForm.welfare.split(',')
            this.welfare = this.citiesGroup
          }
          this.timeRange = [ this.ruleForm.startTime, this.ruleForm.endTime ]
          console.log(this.ruleForm);
        }else{
          this.activeName = 'second'
          mergeObject(this.form,res.job)
          if(this.form.welfare) {
            this.citiesGroup = this.form.welfare.split(',')
            this.welfare = this.citiesGroup
          }
        }
      }
    },
    //点击其它
    openInput(){
      this.input = true
    },
    // input加入welfare
    pushArr(){
      this.welfare.push(this.inputText)
    },
    // 添加按钮
    async submitForm(val){
      if(val==this.ruleForm) {
        this.$refs.ruleFormRef.validate((valid) => {
          if (valid) {
            this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
              this.bLoading = true
              try {
                let params = Object.assign({}, val);
                params.ids = params.ids.map(v=>v.id) 
                let str = this.welfare.join(',')
                this.ruleForm.welfare = str
                const res = await this.jobAddJob(params)
                  this.jobDelete({id:this.$route.query.id})
                  // this.$message.success('发布成功')
                  this.$refs.ruleFormRef.resetFields()
                  this.$refs.formRef.resetFields()
                  this.ruleForm.ids = []
                  this.welfare = []
                  this.inputText = ''
                  this.input = false
                  this.timeRange = []
                // 跳转到兼职列表页
                  this.$router.push('/partTime')
              }catch(e){
                log(e)
              }finally {
                this.bLoading = false
              }
            })
          }
        })
      }else if(val == this.form){
        this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.$confirm('确认提交保存吗？', '提示', {}).then(async () => {
            this.bLoading = true
            try {
              let params = Object.assign({}, val);
              params.ids = params.ids.map(v=>v.id) 
              let str = this.welfare.join(',')
              this.ruleForm.welfare = str
              const res = await this.jobAddJob(params)
              this.jobDelete({id:this.$route.query.id})
              this.$refs.ruleFormRef.resetFields()
              this.$refs.formRef.resetFields()
              this.ruleForm.ids = []
              this.welfare = []
              this.inputText = ''
              this.input = false
              this.timeRange = []
              // 跳转到兼职列表页
              this.$router.push('/partTime')
              this.$message.warning('发布失败')
              }catch(e){
                log(e)
              }finally {
                this.bLoading = false
              }
            })
          }
        })
      }
    },
    // 取消
    resetForm(){
      window.history.go(-1)
      this.$refs.ruleFormRef.resetFields()
      this.$refs.formRef.resetFields()
      this.ruleForm.ids = []
      this.welfare = []
      this.inputText = ''
      this.input = false
      this.timeRange = []
    },
    handleBeforeUploadAvatar(img, callback) {
      const isImg2M = img.size / 1024 / 1024 < 100;
      const isImg =
        img.name.includes("jpg") ||
        img.name.includes("png");
      if (!isImg) {
        this.$message.error("只能上传JPG/PNG!");
        return;
      }
      if (!isImg2M) {
        this.$message.error("上传文件大小不能超过 100MB!");
        return;
      }
      callback(isImg && isImg2M);
    },
    formatDate(now) { 
      var year=now.getFullYear();
      var month=now.getMonth()+1;
      var date=now.getDate();
      return year+"-"+month+"-"+date; 
    },
    getTimeRange(){
     let start = new Date(this.timeRange[0])
     let end = new Date(this.timeRange[1])
     this.ruleForm.startTime = this.formatDate(start)
     this.ruleForm.endTime = this.formatDate(end)
    },
  },
  activated(){
    this.getDetail()
  },
  mounted(){
     this.getDetail()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";
.content-wrapper{
  background: #fff;
}
.wrap{ width: 100%; min-height: 100%;background: #fff;padding:20px;
  .title{ height: 30px; line-height: 30px; border-bottom:1px solid $c-border;
    .titlespan{position:relative;border-bottom:4px solid $c-success;padding-bottom:5px;font-size:16px;font-weight: 600;
    }
  }
  .partTit{ font-size: 16px; font-weight: 600px; color: $c-2; margin-bottom: 20px;}
  .footer{ width: 100%;text-align: center;
    .el-button{width: 140px;margin-right: 20px;}
  }
  .icon{ 
    .el-input__inner{padding-left: 40px; }
  }

}
.el-checkbox-button__inner{ margin-right: 20px;border: 1px solid $c-border; }
.el-checkbox-button:last-child .el-checkbox-button__inner{ border-radius: 10px; }
.el-checkbox-button:first-child .el-checkbox-button__inner{ border-radius: 10px; }
.el-checkbox-button--medium .el-checkbox-button__inner{ border-radius: 10px; }
.el-tabs__nav-scroll{ margin-left: 20px; }
</style>
